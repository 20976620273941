import React from 'react';
import './App.css';
import logo from './heroLogo.png'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import PrivacyPolicy from './components/PrivacyPolicy.js';

function App() {
  return (
    <Router>
      <div className="App">
        <header 
          className="App-header" >
        <img src={logo} alt="logo" style={{height: '225px', width: '330px', paddingTop:'50px'}}></img>
          <h1>
            Contacts Crossword
          </h1>
          <h4 style={{marginTop:'-25px'}} >The only crossword built from your own contacts</h4>
          <a href="https://apps.apple.com/us/app/contacts-crossword/id1505224642?mt=8" className="App-appStoreLink" ></a>
          <Switch>
            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
          </Switch>
        </header>
        <footer className="App-footer">
          <p>
            Contact Us <br /><br/>
            Contacts Crossword <br/>
            contact@contactscrossword.com
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
